import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

const Disclaimer = () => {
  return (
    <Layout>
      <SEO title="Disclaimer" description="" />
      <div className="consulting-wrapper wrapper py-5">
        <h1 className="mt-3">DISCLAIMER</h1>
        <b>Last updated July 25, 2022</b>
        <h3 className="mt-3">WEBSITE DISCLAIMER</h3>
        <p>
          The information provided by Intermedia Marketing Group LLC ("we,"
          "us," or "our") on IntermediaMarketingGroup.com (the "Site") is for
          general informational purposes only. All information on the Site is
          provided in good faith, however we make no representation or warranty
          of any kind, express or implied, regarding the accuracy, adequacy,
          validity, reliability, availability, or completeness of any
          information on the Site. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY
          LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
          RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED
          ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION
          ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <h3 className="mt-3">PROFESSIONAL DISCLAIMER</h3>

        <p>
          The Site cannot and does not contain specific product advice.
          The specific product information is provided for general informational
          and educational purposes only and is not a substitute for professional
          advice. Accordingly, before taking any actions based upon such
          information, we encourage you to consult with the appropriate
          professionals. We do not provide any kind of Specific
          product advice. THE USE OR RELIANCE OF ANY INFORMATION CONTAINED
          ON THE SITE IS SOLELY AT YOUR OWN RISK.
        </p>
        <h3 className="mt-3">TCPA DISCLAIMER</h3>

        <p>
          Notwithstanding any current or prior election to opt in or opt out of
          receiving telemarketing calls, or SMS messages (text messages) from
          our representatives, agents, affiliates, you expressly consent to be
          contacted by INTERMEDIA MARKETING GROUP LLC, our agents,
          representatives, affiliates, or anyone calling on our behalf. This is
          for any purposes relating to your request for specific product or
          service, at any telephone number, or physical or electronic address
          you provide or at which you may be reached. You agree we may contact
          you in any way, including SMS messages (text messages), calls using
          prerecorded messages or artificial voice, and calls and messages
          delivered using auto telephone dialing system or an automatic texting
          system.
        </p>
      </div>
    </Layout>
  )
}

export default Disclaimer
